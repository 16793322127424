// TODO: remove magic numbers
export const getCappedX = (x:  number, width: number = 0): number => {
    if (x < 20) {
        return 20;
    }

    if (x + width > (640 - 20)) {
        return (640 - 20 - width);
    }

    return x;
}