import { useEffect } from 'react';

const useKeyboardEvent = (keys: Array<KeyboardEvent["key"]>, callback: Function) => {
    const onKeyDown = (event: KeyboardEvent) => {
      const wasAnyKeyPressed = keys.some((key) => event.key === key);    if (wasAnyKeyPressed) {
        event.preventDefault();
        callback();
      }
    };
    useEffect(() => {
      document.addEventListener('keydown', onKeyDown);
      return () => {
        document.removeEventListener('keydown', onKeyDown);
    };
    }, [onKeyDown]);
  };

export default useKeyboardEvent;