// TODO: it needs a bounding box-check

import { Sprite } from "@pixi/react";
import Position from "../types/Position";

export type WallProps = Omit<Position, "y">;

const Wall = ({ x }: WallProps) => {
    return (<Sprite
        image={"textures/wall.png"}
        width={20}
        height={480}
        x={x}
        y={0}
        anchor={({ x: 0.0, y: 0.0 })}
    />)
}

export default Wall;