import Size from "../types/Size";

// TODO: canvas size should be increased
// TODO: maybe a vertical layout would work better in this case
const useCanvasSize = (): Size => ({
    width: 640,
    height: 480,
  })
  
export default useCanvasSize;
