import React from 'react';
import { Container, Stage, Text } from '@pixi/react';
import useCanvasSize from './hooks/useCanvasSize';
import Player from './components/Player';
import Level from './components/Level';
import Size from './types/Size';
import Position from './types/Position';
import { atom, useAtom } from 'jotai';
import useLevelGenerator from './hooks/useLevelGenerator';
import { LEVEL_SIZE } from './utilities/constants';
import Wall from './components/Wall';
import { TextStyle } from 'pixi.js';

export const isAliveAtom = atom<boolean>(true)

export const playerNode = atom<Size & Position>({
  width: 39,
  height: 56,
  x: 200,
  y: 350
})

export const levelNodes = atom<Array<Size & Position & { id: number }>>([
  {
    ...LEVEL_SIZE,
    width: 640,
    id: 0,
    x: 20,
    y: 480 - LEVEL_SIZE.height
  },
  {
    ...LEVEL_SIZE,
    id: 1,
    x: 150,
    y: 380 - LEVEL_SIZE.height
  },
  {
    ...LEVEL_SIZE,
    id: 2,
    x: 150,
    y: 280 - LEVEL_SIZE.height
  },
  {
    ...LEVEL_SIZE,
    id: 3,
    x: 150,
    y: 180 - LEVEL_SIZE.height
  }
])

export const scoreAtom = atom<number>(0)

function App() {
  const { width, height } = useCanvasSize();
  const [levels] = useAtom(levelNodes)
  const [isAlive] = useAtom(isAliveAtom)
  const [score] = useAtom(scoreAtom)

  useLevelGenerator()

  // TODO: move out constants
  return (
    <div className="App">
      <Stage width={width} height={height}>
        <Container x={320} y={75}>
          {!isAlive && <>
            <Text text={`Balance: ${score} points`}
              anchor={{ x: 0.5, y: 0.5 }} y={-50} style={new TextStyle({
                stroke: 'red', fill: 'red'
              })} />
            <Text text="Workforce Item 2331 is dead."
              anchor={{ x: 0.5, y: 0.5 }} style={new TextStyle({
                stroke: 'red', fill: 'red'
              })} />
            <Text text="[RE: last letter]"
              anchor={{ x: 0.5, y: 0.5 }} y={50} style={new TextStyle({
                stroke: 'red', fill: 'red'
              })} />
            <Text text="I am kindly reminding you that"
              anchor={{ x: 0.5, y: 0.5 }} y={100} style={new TextStyle({
                stroke: 'red', fill: 'red'
              })} />
            <Text text=" your contract is still valid."
              anchor={{ x: 0.5, y: 0.5 }} y={150} style={new TextStyle({
                stroke: 'red', fill: 'red'
              })} />
            <Text text="Zeta Corporation cannot"
              anchor={{ x: 0.5, y: 0.5 }} y={200} style={new TextStyle({
                stroke: 'red', fill: 'red'
              })} />
            <Text text="afford to lose valuable resources."
              anchor={{ x: 0.5, y: 0.5 }} y={250} style={new TextStyle({
                stroke: 'red', fill: 'red'
              })} />
            <Text text="Hit reload to replay memory chip."
              anchor={{ x: 0.5, y: 0.5 }} y={300} style={new TextStyle({
                stroke: 'red', fill: 'red'
              })} />
            <Text text="COMPLY"
              anchor={{ x: 0.5, y: 0.5 }} y={350} style={new TextStyle({
                stroke: 'red', fill: 'red'
              })} /></>
          }
        </Container>
        <Player />
        <Wall x={0} />
        <Wall x={640 - 20} />
        {levels.map((level) => (<Level id={level.id} key={level.id} />))}
      </Stage>
      <div>A: move left | D: move right | space: jump</div>
    </div>
  );
}

export default App;
