import { Sprite, useTick } from '@pixi/react';
import React from 'react';
import useKeyboardEvent from '../hooks/useKeyboardEvent';
import { atom, useAtom } from 'jotai';
import { isAliveAtom, playerNode } from '../App';
import useGravity from '../hooks/useGravity';
import useScore from '../hooks/useScore';
import { getCappedX } from '../utilities/get-capped-x';

const leftMoveAtom = atom(0)
const rightMoveAtom = atom(0)
const upMoveAtom = atom(0)

const Player = () => {
    const [node, setNode] = useAtom(playerNode);
    
    const [leftMove, setLeftMove] = useAtom(leftMoveAtom)
    const [rightMove, setRightMove] = useAtom(rightMoveAtom)
    const [upMove, setUpMove] = useAtom(upMoveAtom)

    const shouldFall = useGravity();

    const [isAlive] = useAtom(isAliveAtom)

    useScore();

    useTick(delta => {
        if (shouldFall) {
            setNode({...node, y: node.y + delta * 3 })
        }

        setLeftMove((node.x - (65 * delta)))
        setRightMove((node.x + (65 * delta)))
        setUpMove((node.y - (140 * delta)))
    });

    useKeyboardEvent(["a"], () => {
        if (isAlive) {
            setNode({ ...node, x: getCappedX(leftMove, node.width) });
        }
    })

    useKeyboardEvent(["d"], () => {
        if (isAlive) {
            setNode({ ...node, x: getCappedX(rightMove, node.width) });
        }
    })

    useKeyboardEvent([" "], () => {
        if (!shouldFall && isAlive) {
            setNode({ ...node, y: upMove });
        }
    })

    return (<Sprite
      image="textures/player.png"
      width={node.width}
      height={node.height}
      x={node.x}
      y={node.y}
      anchor={{ x: 0.0, y: 0.0 }}
    />)
  }

  export default Player;