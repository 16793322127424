import { Container, Sprite, useTick, Text } from "@pixi/react";
import React from 'react';
import { isAliveAtom, levelNodes, scoreAtom } from "../App";
import { useAtom } from "jotai";
import { TextStyle} from 'pixi.js';
import { LEVEL_SIZE, LEVEL_TEXTURES } from "../utilities/constants";

type LevelProps = {
  id: number;
};

// TODO: animations would be great, but nice-to-haves
const Level = ({ id }: LevelProps) => {
  const [nodes, setNodes] = useAtom(levelNodes)
  const { width, height, x, y } = nodes.find((node) => node.id === id) || { ...LEVEL_SIZE, x: 20, y: 0 }
  const [score] = useAtom(scoreAtom)
  const [isAlive] = useAtom(isAliveAtom)

  const image: string = (() => {
    if (id === score + 1) {
      return LEVEL_TEXTURES.get("ACTUAL") || ""
    }

    if (id > score) {
      return LEVEL_TEXTURES.get("NEXT") || ""
    }

    return LEVEL_TEXTURES.get("PREVIOUS") || ""
  })()

  useTick((delta) => {
    // TODO: remove magic numbers 
    setNodes(nodes.map((level) => ({ ...level, y: level.id <= 5 || !isAlive ? level.y : level.y + (0.17 * delta) })))
  })

  return (
    <Container x={x} y={y}>
      <Text text={`${id}`} anchor={{ x: 1.0, y: 1.0 }} style={new TextStyle({
        stroke: 'white', fill: 'white'
      })} />
      <Sprite
        image={image}
        width={width}
        height={height}
        x={0}
        y={0}
        anchor={({ x: 0.0, y: 0.0 })}
      />
    </Container>
  )
}

export default Level;