import { useAtom } from "jotai";
import { levelNodes, playerNode, scoreAtom } from "../App";
import Position from "../types/Position";
import Size from "../types/Size";
import { LEVEL_SIZE } from "../utilities/constants";
import { getCappedX } from "../utilities/get-capped-x";

const offsetValue = 50; // TODO: extract magic numbers

const getRandomX = (topLevelX: number): number => {
    const min = Math.ceil(0);
    const max = Math.floor(2);
    const offsetSide = Math.floor(Math.random() * (max - min + 1) + min);
    switch (offsetSide) {
        case 0: return getCappedX(topLevelX - offsetValue, LEVEL_SIZE.width);
        case 1: return getCappedX(topLevelX + offsetValue, LEVEL_SIZE.width);
        default: return topLevelX;
    }
  }

const useLevelGenerator = (): void => {
    const [player, setPlayer] = useAtom(playerNode)
    const [levels, setLevels] = useAtom(levelNodes)

    // TODO: remove magic numbers
    if (player.y < 150) {
        const numberOfLevelsRendered = levels.length;
        const topLevel = levels[numberOfLevelsRendered - 1]
        const newLevel: Size & Position & { id: number }  = {
            ...LEVEL_SIZE,
            id: numberOfLevelsRendered,
            y: topLevel.y - 50,
            x: getRandomX(topLevel.x),
        }

        setLevels([...levels.map((level) => ({ ...level, y: level.y + offsetValue })), newLevel])
        setPlayer({ ...player, y: player.y + offsetValue })
    }
}

export default useLevelGenerator;