import { useAtom } from "jotai";
import { levelNodes, playerNode, scoreAtom } from "../App";

const useScore = (): void => {
    const [score, setScore] = useAtom(scoreAtom)
    const [player] = useAtom(playerNode)
    const [levels] = useAtom(levelNodes)

    const newScore: number = levels.reduce((maxLevelReached, level): number => (player.y + player.height < level.y ? level.id : maxLevelReached), score)
    setScore(newScore > score ? newScore : score);
}

export default useScore;