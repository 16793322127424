import { useAtom } from "jotai";
import { isAliveAtom, levelNodes, playerNode, scoreAtom } from "../App";

// TODO: useTick for sync (I'M NOT SURE THHOUGH)
// TODO: maybe I should include some offset to reduce bouncing
const useGravity = (): boolean => {
    const [player] = useAtom(playerNode)
    const [levels, setLevels] = useAtom(levelNodes)
    const [score] = useAtom(scoreAtom)
    const [isAlive, setIsAlive] = useAtom(isAliveAtom)

    const shouldFall = levels.every((level, index) => {
        if (!isAlive) {
            return false;
        }

        // TODO: this should be ideally in a separate function
        // TODO: use canvas height
        if (player.y > 480) {
            setIsAlive(false);
            setLevels([])
            return false;
        }

        if (player.y  > level.y + level.height) {
            return true
        }

        if (player.y + player.height < level.y) {
            return true;
        }

        if (player.y + player.height > level.y + 4) {
            return true
        }

        if (player.x > level.x + level.width) {
            return true;
        }

        if (player.x + player.width < level.x) {
            return true;
        }

        return false;
    })

    return shouldFall;
}

export default useGravity;