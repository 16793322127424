import Size from "../types/Size";

export const LEVEL_TEXTURES = new Map([
    ["PREVIOUS", "textures/prev_level.png"],
    ["ACTUAL", "textures/actual-level.png"],
    ["NEXT", "textures/next_level.png"],
]);

export const LEVEL_SIZE: Size = {
    width: 300,
    height: 20,
};
